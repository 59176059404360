import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSun, faMoon, faPlus, faEquals, faBars, faRedo,
  faSignOutAlt, faTrashAlt, faArrowLeft, faGlobeAmericas, faShareAlt,
  faQuestionCircle, faInfoCircle, faHome, faDatabase, faChevronLeft, faPrint,
  faPlusCircle, faHourglass, faMobile, faFilter, faAngleRight,
  faBuilding, faDownload, faMobileAlt, faChevronRight,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHeart, faFile, faFilePdf,
} from '@fortawesome/free-regular-svg-icons';

library.add([
  faSun,
  faMoon,
  faPlus,
  faEquals,
  faBars,
  faRedo,
  faQuestionCircle,
  faInfoCircle,
  faHome,
  faDatabase,
  faChevronLeft,
  faChevronRight,
  faSignOutAlt,
  faTrashAlt,
  faArrowLeft,
  faGlobeAmericas,
  faShareAlt,
  faFacebook,
  faTwitterSquare,
  faPrint,
  faPlusCircle,
  faHourglass,
  faMobile,
  faFilter,
  faAngleRight,
  faBuilding,
  faDownload,
  faUser,
  faHeart,
  faFile,
  faMobileAlt,
  faFilePdf,
]);

Vue.component('font-awesome-icon', FontAwesomeIcon);
