import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/VueMeta';
import './plugins/BootstrapVue';
import './plugins/FontAwesome';
import './plugins/CompositionApi';
import './plugins/InicializarOscuro';

import './styles/Base-escritorio.scss';
import './styles/tarjetas.scss';
import './styles/graficas.scss';
import './styles/Base.scss';

Vue.config.productionTip = false;

store.dispatch('inicializar');

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
